$t3r-grey: #231f20;
$t3r-grey1: #3F3F3F;
$t3r-gray2: #231f20;
$t3r-yellow: #fae81e;
$t3r-yellow-dark: #9d951c;

img.logo {
    width: 8rem;
    height: auto;
}

object.logo {
    width: 8rem;
    height: auto;
    aspect-ratio: 1/1;
}

.t3r-theme-dark {
    background-color: $t3r-grey;
    color: white;
}

.t3r-theme-dark h1, .t3r-theme-dark h3 {
    color: white;
}
