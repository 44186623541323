//@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&family=Oswald:wght@400&family=Raleway:wght@300&family=Sawarabi+Gothic&display=swap');

@import "logo";
@import "settings/settings";

@import "foundation";
@import "motion-ui";
@import "blocks/kevin/product_image_gallery";
@import "blocks/rafi/marketing_footer";

@include foundation-everything(true);
@include motion-ui-transitions;
@include motion-ui-animations;

@import "product_configure";

table.navbar-menu thead {
  background-color: green;
}
table.navbar-menu tbody tr {
  background-color: green;
}
.top-bar {
  background-color: black;
}

.top-bar-right li {
  background-color: black;
  color: lighten($t3r-grey, 40%);
}

.top-bar-right li a {
  background-color: black;
  color: lighten($t3r-grey, 40%);
}

.no-border {
  border: 0
}

.home p > img, img[alt=about-t3r-entrance] {
  display: block;
  margin: 0 auto;
  max-width: 50%;
}

img.home {
  display: block;
  margin: 0 auto;
}

.contact textarea {
  height: 20vh;
}

.t3rmenu {
  $lower-bound-size: map-get($breakpoints, medium);
  @media screen and (max-width: $lower-bound-size) {
    height: 8rem;
  }
  @media screen and (min-width: $lower-bound-size) {
    height: 100vh;
  }

}

@font-face {
  font-family: 'emoji';
  src: url('/assets/TwemojiMozilla.ttf');
}
p.emoji {
  display: inline;
  font-family: 'emoji';
}
