$unselected-color: #5e5e5e;
$selected-color: #d0d030;

$table-background: $t3r-grey;

.button-group, .button-group:hover {
    background-color: white;
}

.button {
    background-color: $unselected-color;
}
.button:hover {
    background-color: scale-color($unselected-color, $lightness: 15%);
}
.button[disabled], .button[disabled]:hover {
    background-color: scale-color(white, $lightness: -15%);
}

.button-chooseone.active:hover {
    background-color: $selected-color;
}
.button-chooseone.active {
    background-color: $selected-color;
    color: black;
}
